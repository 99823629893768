import { Disclosure } from "@headlessui/react"
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline"

function NavbarLink(props) {
  return (
    <li className="hover:border-white border-b-2 border-black transition-all">
      <a href={props.link.url}>{props.link.text}</a>
    </li>
  );
}

export default function Navbar() {
  let links = [
    { "text": "Offensive Security", "url": "/offensive" },
    { "text": "Defensive Security", "url": "/defensive" },
    { "text": "Request Quote", "url": "/quote" },
    { "text": "Contact", "url": "/contact" },
  ];

  return (
    <Disclosure as="nav" className="bg-black">
      {({ open }) => (
        <>
          <div className="sticky top-0 w-100 h-20 bg-black text-white flex flex-row items-center gap-12 justify-between z-10">
              <div className="ps-4">
                <a href="/">
                  <img
                    src="logo.png"
                    alt="Vigilant Logo"
                    className="object-contain h-8 w-52 sm:h-9 sm:w-46 pl-4"
                  />
                </a>
              </div>
              <div className="lg:hidden">
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open navigation menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                </Disclosure.Button>
              </div>
              <div className="hidden lg:block">
                <ul className="pr-8 flex flex-row gap-6 text-lg font-semibold">
                  {links.map((link) => <NavbarLink key={link.text} link={link} />)}
                </ul>
              </div>
          </div>

          <Disclosure.Panel className="sm:hidden text-white">
            <div className="space-y-1 px-2 pb-3 pt-2 text-center">
              {links.map((link) => (
                <Disclosure.Button key={link.text} as="a" href={link.url} className="block rounded-md px-3 py-2 text-base font-medium hover:bg-gray-800">
                  {link.text}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
    )}
    </Disclosure>
  );
}
